import React from 'react'

function AboutSegmentOne() {
  return (
    <div className='about_segment_one'>
      <div className="about_segment_one_sub">
      <div className="about_segment_one_text">
      <h2>About Draft-IT!</h2>
      <h6>OUR MISSION</h6>
      <p> Draft-IT strives to be the premier design firm that specializes in delivering innovative
and smart development solutions via designs that are up to the mark for our clients.</p>
    
      </div>

      <div className="about_segment_one_image">

      </div>
      </div>
      
     </div>
  )
}

export default AboutSegmentOne