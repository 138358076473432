import React from 'react'
import image1 from '../../ASSETS/FeaturedWork/One.png'
import image2 from '../../ASSETS/FeaturedWork/Four.jpg'
import image3 from '../../ASSETS/FeaturedWork/Six.jpg'

function AboutSegmentFour() {
  return (
    <div className='about_segment_four'>
      <h1>Featured Works</h1>

      <div className="about_work_cards">
        <div className="about_work_card">
          <img src = {image1} alt="" />
        </div>
        <div className="about_work_card">
        <img src={image2} alt="" />
        </div>
        <div className="about_work_card">
        <img src={image3} alt="" />
        </div>
      </div>

    </div>
  )
}

export default AboutSegmentFour