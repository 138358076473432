import React from 'react'
import HomeSegmentOne from './HomeSegmentOne'
import './Home.css'
import Services from './Services'
import HomeSegmentThree from './HomeSegmentThree'
import Home_segment_four from './Home_segment_four'
import HomeSelectedWork from './HomeSelectedWork'
import HomeContactUs from './HomeContactUs'
import Carousel from '../../COMPONENTS/CAROUSEL/Carousel'

function Home() {
  return (
    <div className='home'>
      <HomeSegmentOne/>
      <Services/>
      <HomeSegmentThree/>
      <HomeSelectedWork/>
      <Home_segment_four/>
      {/* <Carousel/> */}
      <HomeContactUs/>

    </div>
  )
}

export default Home