import React from 'react'


function ServiceSegmentOne({services, industries}) {

  return (
    <div className=  {industries ? 'service_segment_one service_industries' : 'service_segment_one'} >
       <div className="service_segment_one_title">
       {!industries && <h1>SERVICES</h1>}
       {!industries && <p>We Make What You Desire.</p>}
       {industries && <h1>INDUSTRIES</h1>}
       {industries && <p>We Make What You Desire.</p>}

       </div>
        <div className="all_services">
            {services.map((service, i) =>{
                return <div className="service_segment_one_box">
                    <div className="service_segment_one_box_left">
                        {!industries ? service.logo : <img src={service.logo} alt="" />}
                        
                    </div>
                    <div className="service_segment_one_box_right">
                        <h3>{service.title}</h3>
                        <p>{service.description}</p>
                    </div>
                </div>
            })}
            
        </div>

       
    </div>
  )
}

export default ServiceSegmentOne