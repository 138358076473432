import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import founder from '../../ASSETS/founder.jpg'

function Home_segment_four() {

    const [team, setTeam] = useState([
        {
            image: founder,
            name: "Apoorva Kamble",
            position: "Founder",
            tagline: "Success is not when you win, your hardwork and efforts are priceless !!" 
        },
       

    ])

  return (
    <div className='home_segment_four'>
        <div className="home_sub_segment">
            <div className="home_four_left">
                <h3>CREATIVE AND PROFESSIONAL</h3>
                <p>Our team is proficient at product design and development across diverse multidisciplinary domains, along with that company is specialized into designing
mechanical products as well as static equipment needed for manufacturing. </p>
                <p>Product design expertise enables us to understand the requirement from initial
concept, thorough analysis and validation, drafting a design to final product
specification, which leads into volume manufacturing.  </p>
                <Link to='/AboutUs'><button>Know More</button></Link>
            </div>
            <div className="home_four_right">
                {team.map((e,i) => {
                    return <div className='home_team_card'>
                        <div className="home_team_cir"><img src={e.image} alt="" /></div>
                        <div className="home_team_right">
                            <h4>{e.name}</h4>
                            <p>{e.position}</p>
                            <p>{e.tagline}</p>

                        </div>
                    </div>
                })}
            </div>
        </div>
    </div>
  )
}

export default Home_segment_four