import React from 'react'
import {Link} from 'react-router-dom'

function HomeSegmentOne() {
  return (
    <div className='home_segment_one'>
        <div className="home_segment_one_main_text">
            <h1>Have an Idea that You want to Turn into Design?</h1>
            <p>Our services are tailored specifically according to your individual needs. You name it, we'll <span>CREATE</span>  it.</p>
           <Link to="/ContactUs"> <button>CONTACT US</button></Link> 
        </div>
    </div>
  )
}

export default HomeSegmentOne