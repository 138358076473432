import React from 'react'

function ContactSegmentOne() {
  return (
    <div className='contact_segment_one'>
        <div className="contact_segment_one_text">
            <h4>CONTACT</h4>
            <h1>Get In Touch</h1>
            <p> One must practice to be perfect, so ONE chance would be enough !!!</p>
            
        </div>

        {/* <div className="contact_segment_one_testimonials">

        </div> */}
    </div>
  )
}

export default ContactSegmentOne