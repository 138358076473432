import React from 'react'
import './Services.css'
import ServiceSegmentOdd from './ServiceSegmentOdd'
import ServiceSegmentOne from './ServiceSegmentOne'
import ServiceSegmentTwo from './ServiceSegmentTwo'


import { VscBook } from 'react-icons/vsc'
import { MdOutlineLaptopChromebook } from 'react-icons/md'
import { HiWrenchScrewdriver } from 'react-icons/hi2'
import { GiAncientScrew } from 'react-icons/gi'
import { MdRateReview } from 'react-icons/md'
import { SiSimpleanalytics } from 'react-icons/si'
import { MdModelTraining } from 'react-icons/md'
import { SiApachemaven } from 'react-icons/si'
import ServiceDetails from './ServiceDetails'

import CAD from '../../ASSETS/Images/CAD.jpg'
import PDD from '../../ASSETS/Images/Product Design and Development_2.jpg'
import ME from '../../ASSETS/Images/Manufacturing Engineering_3.jpg'
import DR from '../../ASSETS/Images/Design Review.jpg'
import simultaion from '../../ASSETS/Images/Simulations.jpg'
import VD from '../../ASSETS/Images/Vendor Development_1.jpg'
import Training from '../../ASSETS/Images/Training.jpg'

import Aerosapce from '../../ASSETS/Industries ICON/Aerospace.png'
import Automobile from '../../ASSETS/Industries ICON/Automobile.png'
import Boileranfvalve from '../../ASSETS/Industries ICON/Boiler anf valve.png'
import FoodIndustries from '../../ASSETS/Industries ICON/Food Industries.png'
import Industrialproduct from '../../ASSETS/Industries ICON/Industrial product.png'
import Manufacturing from '../../ASSETS/Industries ICON/Manufacturing.png'
import TurboMachine from '../../ASSETS/Industries ICON/Turbo Machine.png'




function Services() {



  const services = [{
    title: "Product Design and Development.",
    image: PDD,
    points: [
      "Creating technical specifications for products based on customer requirements.",
      "Design optimization, Design for product manufacturing, product assembly.",
      "Detailed manufacturing drawings, Concept design.",
      "Design calculations and sizing.",
      "Creation of 3D P&ID station as per circuit diagram (We don’t provide design calculation for P&ID it must be provided by client’s process engineer)"
    ],
    description: "Our team of experts is well-equipped to provide our customers with the full range of product design and development services.",
    logo: <VscBook />,
    breifDescription: "With our expertise and capabilities, we undertake the entire product design and developmentprocess, as well as supporting customers in improving their current products through the use ofengineering activities such as value engineering, customized products, and individualcustomizations"
  },
  {
    title: "Computer aided design",
    image: CAD,
    points: [
      " Creating 2D manufacturing drawings and assembly / Technical drawings.",
      " Creating 2D IBR Drawings/IBR Design calculation.",
      " General Arrangement (GA) and Layout drawings Bill of material (BoM).",
      " Design calculation and Valve thickness calculation."
    ],
    description: "Draft-IT Design solutions helps in creating Design/Simulations of real-world goods and products in 2D or 3D.",
    logo: <MdOutlineLaptopChromebook />,
    breifDescription: "Draft-IT Design solutions helps in creating Design/Simulations of real-world goods and productsin 2D or 3D, complete with scale, precision, and physics properties, which allow the design to be optimized and perfected before production."

  },
  {
    title: "Manufacturing Engineering",
    image: ME,
    points: [
      " Tooling and fixture design support.",
      " Design and development of manufacturing processes.",
      " Development of assembly instructions.",
      " Design of Jigs and Fixtures for manufacturing, assembly and inspection.",
      " Design for Casting and Forging"
    ],
    description: "The manufacturing engineering team supports our customers throughout the product development, CAD, and production phases",
    logo: <HiWrenchScrewdriver />,
    breifDescription: "We support our customers in the product design and development and computer aided designs as well as during the production phase, our manufacturing engineering team supports customers in the production phase as well. Activities our manufacturing engineers handle in this manufacturing area include:"

  },
  // {
  //     title: "Structural Engineering",
  //     image: "https://plus.unsplash.com/premium_photo-1672287598510-58cba510e395?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      // description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
  //     logo: <GiAncientScrew/>,
      // breifDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni similique nostrum facilis, sunt voluptatum explicabo tempora quod doloribus quaerat distinctio, rerum in vel aperiam quo corrupti natus tenetur iure accusantium!Eius adipisci officia nisi iste voluptatem sit consequatur repellat, tempora at mollitia ipsa repudiandae ea facere unde illo, expedita suscipit maxime similique doloribus. Ex nulla libero doloribus vel, natus voluptatibus?Pariatur numquam placeat repellat maiores illum? Laborum neque nisi consectetur fuga ipsum veritatis sequi aperiam. Veritatis, error ipsum. Mollitia excepturi voluptatem ad ratione enim officia libero in vero accusamus eius."

  // },

  {
    title: "Design Review",
    image: DR,
    points: [
      " Verify that the designs and drawings conform to the provisions of the relevant codes and standards.",
      " Reviewing Designs and Drawings.",
      " Suggest possibility of cost savings in the designs",
      " Provide feedback on the designs and drawings."
    ],
    description: "We at Draft-IT have a team of highly skilled engineers who are capable of providing comprehensive 3rd party design and drawing review services.",
    logo: <MdRateReview />,
    breifDescription: "Are you looking for a reliable third-party reviewing agency to review your designs and drawings? Look no further! We are a specialized design review company providing comprehensive and high-quality reviews of a variety of designs and drawings. We at Draft-IT have a team of highly skilled engineers who are capable of providing comprehensive 3rd party design and drawing review services."

  },

  {
    title: "Engineering analysis and simulation",
    image: simultaion,
    points: [
      " FEA (Finite Element Analysis).",
      " CFD (Computational Fluid Dynamics Analysis).",
      " Design Calculations/IBR Design Calculations."
    ],
    description: "Draft-IT provides expertise in many aspects of simulation and analysis for use in product design, manufacturing processes, and failure analysis.",
    logo: <SiSimpleanalytics />,
    breifDescription: "Product design lifecycle costs and time have been significantly reduced as a result of simulations used to verify the design. In addition to helping understand potential failure modes, simulations also provide insight into margins available for optimization."

  },

  {
    title: "Training",
    image: Training,
    points: [
      "Creo",
      " SolidWorks",
      " NX",
      " CATIA",
      " Autodesk Inventor",
      " AutoCAD"

    ],
    description: "At Draft-IT design solutions, we provide professional and expert training on CAD-based mechanical design software.",
    logo: <MdModelTraining />,
    breifDescription: "At Draft-IT design solutions, we provide professional and expert training on CAD-based mechanical design software. Our experienced instructors ensure you get the most out of each training session by focusing on the unique needs of each student. With our help, you can become an expert in no time. We provide both online and offline training courses which are flexible and can be customized according to your needs. We provide Professional & Expert training on Mechanical Design Software like: "

  },

  // {
  //   title: "Vendor development",
  //   image: VD,
  //   points: [
  //     " Create RFQs by understanding customer requirements.",
  //     " Interaction with vendors",
  //     " Communicating quality expectations to suppliers and understanding their expectations."
  //   ],
  //   description: "Our team has years of experience in the Indian manufacturing market and we only work with vendors that meet our strict quality standards.",
  //   logo: <SiApachemaven />,
  //   breifDescription: "We connect global customers with qualified manufacturing vendors in India. We work with you to understand your sourcing needs and then match you with the right supplier. Our team has years of experience in the Indian manufacturing market and we only work with vendors that meet our strict quality standards."

  // },
  ]

  const industries = [{
    title: "Manufacturing Industry",
    image: "https://plus.unsplash.com/premium_photo-1672287598510-58cba510e395?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    // description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
    logo: Manufacturing,
    // breifDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni similique nostrum facilis, sunt voluptatum explicabo tempora quod doloribus quaerat distinctio, rerum in vel aperiam quo corrupti natus tenetur iure accusantium!Eius adipisci officia nisi iste voluptatem sit consequatur repellat, tempora at mollitia ipsa repudiandae ea facere unde illo, expedita suscipit maxime similique doloribus. Ex nulla libero doloribus vel, natus voluptatibus?Pariatur numquam placeat repellat maiores illum? Laborum neque nisi consectetur fuga ipsum veritatis sequi aperiam. Veritatis, error ipsum. Mollitia excepturi voluptatem ad ratione enim officia libero in vero accusamus eius."
  },
  {
    title: "Boilers and Valve ",
    image: "https://plus.unsplash.com/premium_photo-1672287598510-58cba510e395?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    // description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
    logo: Boileranfvalve,
    // breifDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni similique nostrum facilis, sunt voluptatum explicabo tempora quod doloribus quaerat distinctio, rerum in vel aperiam quo corrupti natus tenetur iure accusantium!Eius adipisci officia nisi iste voluptatem sit consequatur repellat, tempora at mollitia ipsa repudiandae ea facere unde illo, expedita suscipit maxime similique doloribus. Ex nulla libero doloribus vel, natus voluptatibus?Pariatur numquam placeat repellat maiores illum? Laborum neque nisi consectetur fuga ipsum veritatis sequi aperiam. Veritatis, error ipsum. Mollitia excepturi voluptatem ad ratione enim officia libero in vero accusamus eius."

  },
  {
    title: "Food Industries",
    image: "https://plus.unsplash.com/premium_photo-1672287598510-58cba510e395?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    // description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
    logo: FoodIndustries,
    // breifDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni similique nostrum facilis, sunt voluptatum explicabo tempora quod doloribus quaerat distinctio, rerum in vel aperiam quo corrupti natus tenetur iure accusantium!Eius adipisci officia nisi iste voluptatem sit consequatur repellat, tempora at mollitia ipsa repudiandae ea facere unde illo, expedita suscipit maxime similique doloribus. Ex nulla libero doloribus vel, natus voluptatibus?Pariatur numquam placeat repellat maiores illum? Laborum neque nisi consectetur fuga ipsum veritatis sequi aperiam. Veritatis, error ipsum. Mollitia excepturi voluptatem ad ratione enim officia libero in vero accusamus eius."

  },
  {
    title: "Industrial products and Machines",
    image: "https://plus.unsplash.com/premium_photo-1672287598510-58cba510e395?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    // description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
    logo: Industrialproduct,
    // breifDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni similique nostrum facilis, sunt voluptatum explicabo tempora quod doloribus quaerat distinctio, rerum in vel aperiam quo corrupti natus tenetur iure accusantium!Eius adipisci officia nisi iste voluptatem sit consequatur repellat, tempora at mollitia ipsa repudiandae ea facere unde illo, expedita suscipit maxime similique doloribus. Ex nulla libero doloribus vel, natus voluptatibus?Pariatur numquam placeat repellat maiores illum? Laborum neque nisi consectetur fuga ipsum veritatis sequi aperiam. Veritatis, error ipsum. Mollitia excepturi voluptatem ad ratione enim officia libero in vero accusamus eius."

  },

  {
    title: "Turbo - machinery",
    image: "https://plus.unsplash.com/premium_photo-1672287598510-58cba510e395?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    // description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
    logo: TurboMachine,
    // breifDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni similique nostrum facilis, sunt voluptatum explicabo tempora quod doloribus quaerat distinctio, rerum in vel aperiam quo corrupti natus tenetur iure accusantium!Eius adipisci officia nisi iste voluptatem sit consequatur repellat, tempora at mollitia ipsa repudiandae ea facere unde illo, expedita suscipit maxime similique doloribus. Ex nulla libero doloribus vel, natus voluptatibus?Pariatur numquam placeat repellat maiores illum? Laborum neque nisi consectetur fuga ipsum veritatis sequi aperiam. Veritatis, error ipsum. Mollitia excepturi voluptatem ad ratione enim officia libero in vero accusamus eius."

  },
  {
    title: "Aerospace",
    image: "https://plus.unsplash.com/premium_photo-1672287598510-58cba510e395?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    // description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
    logo: Aerosapce,
    // breifDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni similique nostrum facilis, sunt voluptatum explicabo tempora quod doloribus quaerat distinctio, rerum in vel aperiam quo corrupti natus tenetur iure accusantium!Eius adipisci officia nisi iste voluptatem sit consequatur repellat, tempora at mollitia ipsa repudiandae ea facere unde illo, expedita suscipit maxime similique doloribus. Ex nulla libero doloribus vel, natus voluptatibus?Pariatur numquam placeat repellat maiores illum? Laborum neque nisi consectetur fuga ipsum veritatis sequi aperiam. Veritatis, error ipsum. Mollitia excepturi voluptatem ad ratione enim officia libero in vero accusamus eius."

  },
  {
    title: "Automotive",
    image: "https://plus.unsplash.com/premium_photo-1672287598510-58cba510e395?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    // description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
    logo: Automobile,
    // breifDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni similique nostrum facilis, sunt voluptatum explicabo tempora quod doloribus quaerat distinctio, rerum in vel aperiam quo corrupti natus tenetur iure accusantium!Eius adipisci officia nisi iste voluptatem sit consequatur repellat, tempora at mollitia ipsa repudiandae ea facere unde illo, expedita suscipit maxime similique doloribus. Ex nulla libero doloribus vel, natus voluptatibus?Pariatur numquam placeat repellat maiores illum? Laborum neque nisi consectetur fuga ipsum veritatis sequi aperiam. Veritatis, error ipsum. Mollitia excepturi voluptatem ad ratione enim officia libero in vero accusamus eius."

  },

  ]


  return (
    <div>
      <p></p>
      <ServiceSegmentOne services={services} industries={false} />
      <ServiceDetails services={services} />
      <ServiceSegmentOne services={industries} industries={true} />
    </div>
  )
}

export default Services