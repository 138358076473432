import React from 'react'
import {AiFillLinkedin} from 'react-icons/ai'
import {BsInstagram} from 'react-icons/bs'
import {FaFacebookSquare} from 'react-icons/fa'
import {FaTwitterSquare} from 'react-icons/fa'
import {FaPinterestSquare} from 'react-icons/fa'
import {AiOutlineDribbbleSquare} from 'react-icons/ai'


function ContactSegmentThree() {
  return (
    <div className='contact_segment_three'>
        <h2>Connect with us !</h2>
        <div className="contact_segment_three_icons">
        <a href="https://www.linkedin.com/company/draft-it-design-solutions/">
          <AiFillLinkedin/>
        </a>
        <a href="http://Twitter.com/DraftIT_design"><FaTwitterSquare/></a>
            <a
        href="https://wa.me/+918080681678"
        class="whatsapp_float"
        target="_blank"
        id='contact_wa'
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
        </div>
    </div>
  )
}

export default ContactSegmentThree