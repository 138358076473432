import React, { useState } from 'react'
import './Footer.css'
import {NavLink} from 'react-router-dom'

import MainLogo from '../../ASSETS/MainLogo/final-01.png'



function Footer() {

    const [socialMedia, setSocialMedia] = useState([
        {
            page: "LINKEDIN",
            link: 'https://www.linkedin.com/company/draft-it-design-solutions/'
        },
        // {
        //     page: "INSTAGRAM",
        //     link: "/"
        // },
        // {
        //     page: "PHONE: +91 8080681678",
        //     // link: '/'
        // },
        {
            page: "TWITTER",
            link: "http://Twitter.com/DraftIT_design"
        },
        // {
        //     page: "WHATSAPP",
        //     link: '/'
        // }
    ])
  return (
    <div className='footer'>
        <img src={MainLogo} alt="" />
        <div className="footer_social_media">
            {socialMedia.map((page, i) => {
                return <a href={page.link}>{page.page}</a>
            })}
            <a
        href="https://wa.me/+918080681678"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        WHATSAPP
      </a>
        </div>

        <p>Draft-IT Design Solutions</p>
    </div>
  )
}

export default Footer