import React from 'react'
import { Link } from 'react-router-dom'

function AboutSegmentFive() {
  return (
    <div className='about_segment_five'>
      <div className="about_segment_five_left">

      </div>
      <div className="about_segment_five_right">
        <h1>
          Creating Designs in Just a Few Clicks.
        </h1>
        <p>
        Draft-IT strive to be a customer centric design firm that meets our client’s needs and provide the
best-in-class engineering solutions to match the final product expectations.
        </p>
        <p>
        We will achieve this goal by providing detailed manufacturing drawings, Installation drawings ,
control plans & developing cutting-edge tactics to design.
        </p>

        <Link to="/ContactUs">
          <button>Contact Us</button>
        </Link>
      </div>
    </div>
  )
}

export default AboutSegmentFive