import React from 'react'
import {Link} from 'react-router-dom'

function HomeContactUs() {
  return (
    <div className='home_contact_us'>
        <h3>Have an Idea that You want to Turn into
Creative Design?</h3>
        <Link to='/ContactUs'><button>Contact Us</button></Link>
    </div>
  )
}

export default HomeContactUs