import React from 'react'



import image1 from '../../ASSETS/FeaturedWork/Five.jpg'
import image2 from '../../ASSETS/FeaturedWork/Two.jpg'
import image3 from '../../ASSETS/FeaturedWork/Seven.jpg'

function HomeSelectedWork() {
  return (
    <div className='home_selected_work'>
      <h1>Featured Works</h1>
      <h4>We Focus On Long Term, Apparent and Provable Value</h4>

      <div className="home_work_cards">
        <div className="home_work_card">
          <img src={image1} alt="" />
        </div>
        <div className="home_work_card">
<img src={image2} alt="" />
        </div>
        <div className="home_work_card">
<img src={image3} alt="" />
        </div>
      </div>

    </div>
  )
}

export default HomeSelectedWork