import React from 'react'
import AboutSegmentFive from './AboutSegmentFive'
import AboutSegmentFour from './AboutSegmentFour'
import AboutSegmentOne from './AboutSegmentOne'
import AboutSegmentThree from './AboutSegmentThree'
import AboutSegmentTwo from './AboutSegmentTwo'
import './AboutUs.css'

function AboutUs() {
  return (
    <div>
      <AboutSegmentOne/>
      <AboutSegmentTwo/>
      <AboutSegmentThree/>
      <AboutSegmentFour/>
      <AboutSegmentFive/>
    </div>
  )
}

export default AboutUs