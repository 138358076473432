import React from 'react'
import {FaRegHandshake, FaDigitalTachograph, } from 'react-icons/fa'
import {RiSeedlingLine} from 'react-icons/ri'
import {Link} from 'react-router-dom'

function Services() {
    return (
        <div className='home_services'>
            <div className="home_left_services">
                <h1>Some Of Our Prime Services</h1>
                <p>Our services provide innovative and development solutions that meet our clients' needs
and expectations with advanced engineering techniques.</p>
                {/* <p>Lorem ipsum dolor sit amet. Lorem, ipsum dolor.</p> */}
                <Link to='/Services'><button>View All</button></Link>
            </div>

            <div className="home_right_services">
                <div className="home_right_service_holder">
                    <div className="home_right_service_cards">
                        <div className="home_right_service_card">
                            <div className="home_service_circle">
                                <FaRegHandshake/>
                            </div>
                            <h1>Product Design and Development.</h1>
                        </div>
                        <div className="home_right_service_card">
                            <div className="home_service_circle">
                                <FaDigitalTachograph/>
                            </div>
                            <h1>Computer aided design</h1>
                        </div>
                        <div className="home_right_service_card">
                            <div className="home_service_circle">
                                <RiSeedlingLine/>
                            </div>
                            <h1>Manufacturing Engineering</h1>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Services