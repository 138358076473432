import './App.css';
import Footer from './COMPONENTS/FOOTER/Footer';
import Nav from './COMPONENTS/NAV/Nav';
import {Routes, Route} from 'react-router-dom'

import Home from './PAGES/HOME/Home'
import AboutUs from './PAGES/ABOUT/AboutUs'
import Services from './PAGES/SERVICES/Services'
import Contact from './PAGES/CONTACT/Contact'
function App() {
  return (
    <div className="app">
      <Nav/>
      <Routes>
        <Route path = '/' element= {<Home/>}/>
        <Route path = '/AboutUs' element={<AboutUs/>}/>
        <Route path='/ContactUs' element={<Contact/>}/>
      <Route path='/Services' element={<Services/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
