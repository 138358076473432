import React, { useState } from 'react'
import {BsCheckCircleFill} from 'react-icons/bs'

// import image1 from '../../ASSETS/FeaturedWork/Five.jpg'
// import image2 from '../../ASSETS/FeaturedWork/Two.jpg'
// import image3 from '../../ASSETS/FeaturedWork/Seven.jpg'

import image1 from '../../ASSETS/Images/Training_2.jpg'
import image2 from '../../ASSETS/Images/Manufacturing Engineering_2.jpg'
import image3 from '../../ASSETS/Images/CAD_2.jpg'


function HomeSegmentThree() {

    const [services, setServices] = useState([
        {
            title: "Compressed development cycles and ensures efficiency.",
            checkbox: <BsCheckCircleFill/>
        },
        {
            title: "Predictable and reliable Designs.  ",
            checkbox: <BsCheckCircleFill/>
        },
        {
            title: "Providing both pre- and post-design support which is the essential complement to our core product design engineering services.",
            checkbox: <BsCheckCircleFill/>
        },
        // {
        //     title: "Lorem ipsum dolor sit amet consectetur adipisicing.",
        //     checkbox: <BsCheckCircleFill/>
        // }

    ])

  return (
    <div className='home_segment_three'>
        <div className="home_segment_three_left">
        <img id='home_one' src={image1} alt="" />
        <img id='home_two' src={image2} alt="" />
        <img id='home_three' src={image3} alt="" />
        </div>
        <div className="home_segment_three_right">
            <h1>We establish a methodology for concurrent product and manufacturing design that results in:  </h1>
            {/* <p>Lorem ipsum dolor sit, Repellendus deleniti, saepe officia voluptate exercitationem pariatur.</p> */}
            {services.map((e, i) => {
                return <div className='home_three_services'> {e.checkbox} <p>{e.title}</p></div>
            })}
        </div>

    </div>
  )
}

export default HomeSegmentThree