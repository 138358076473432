import React from 'react'

function ServiceSegmentTwo({service}) {
  return (
    <div className='service_segment_even'>
        <div className="service_segment_even_left">
            <img src={service.image} alt="" />
        </div>
        <div className="service_segment_even_right">
            <h1>{service.title}</h1>
            <p>{service.breifDescription}</p>
            <ol >
            {service.points.map((e,i) => {
              return <li>{e}</li>
            })}
            </ol>
        </div>
    </div>
  )
}

export default ServiceSegmentTwo