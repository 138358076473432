import React from 'react'
import {GiVanillaFlower} from 'react-icons/gi'

function AboutSegmentThree() {
  return (
    <div className='about_segment_three'>
        <h1><GiVanillaFlower/></h1>
        <div className="about_segment_three_text">
        <p>When you feel stuck at an idea, we are there to help you turn it into design.</p>
        <p>So don't worry about ideating, we have your back!</p>
        </div>
    </div>
  )
}

export default AboutSegmentThree