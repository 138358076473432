import React from 'react'
import './Contact.css'
import ContactSegmentOne from './ContactSegmentOne'
import ContactSegmentThree from './ContactSegmentThree'
import ContactSegmentTwo from './ContactSegmentTwo'
import Carousel from '../../COMPONENTS/CAROUSEL/Carousel'

function Contact() {
  return (
    <div>
      <ContactSegmentOne/>
      <ContactSegmentTwo/>
      <ContactSegmentThree/>
      {/* <Carousel/> */}
    </div>
  )
}

export default Contact