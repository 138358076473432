import React from 'react'
import {FaMobileAlt} from 'react-icons/fa'
import {GoLocation} from 'react-icons/go'
import {TfiEmail} from 'react-icons/tfi'


function ContactSegmentTwo() {
    return (
        <div className='contact_segment_two'>
            <h3>Get in touch with us!</h3>
            <div className="contact_type_boxes">
                <div className="contact_type_box">
                  <h5> <FaMobileAlt/></h5> 
                    <h4>PHONE</h4>
                    <p>Phone | +91 8080681678</p>
                    {/* <p>Office | +91 797979797979</p> */}
                </div>
                <div className="contact_type_box">
                    
                    <h5><GoLocation/></h5>
                    <h4>LOCATION</h4>
                    <p>Shop no 6, Mini Market, Sector 2, Indrayani Nagar,  pune 411039</p>
                </div>
                <div className="contact_type_box">
                    
                    <h5><TfiEmail/></h5>
                    <h4>EMAIL</h4>
                    <p>info@draftitdesign.com</p>
                    {/* <p>dummy2345@dummny.com</p> */}
                </div>
            </div>
        </div>
    )
}

export default ContactSegmentTwo