import React from 'react'
import ServiceSegmentOdd from './ServiceSegmentOdd'
import ServiceSegmentTwo from './ServiceSegmentTwo'

function ServiceDetails({services}) {
  return (
    <div>
        {services.map((service, i) => {
            if(i % 2 == 0) return  <ServiceSegmentTwo service = {service}/>
            else return <ServiceSegmentOdd service = {service}/>
        }) }
       
      
    </div>
  )
}

export default ServiceDetails