import React from 'react'
import image1 from '../../ASSETS/Images/Simulation_2.jpg'
import image2 from '../../ASSETS/Images/Manufacturing Engineering_2.jpg'

function AboutSegmentTwo() {
  return (
    <div className='about_segment_two'>
      <div className="about_segment_two_left">
        <img id='about_left_one' src={image1} alt="" />
        <img id='about_left_two' src={image2} alt="" />
      </div>
      <div className="about_segment_two_right">
        <h3>Meet Draft-IT</h3>
        <h4>Company Overview</h4>
        <p id='about_subtitle'>Draft-IT Design Solutions, a company that provides industrial product design and
          development services for the product manufacturing.</p>
        <div className="about_segment_two_line"></div>
        <p>Draft-IT Design Solutions is a full-service Engineering design firm that provides best
          value added and quality services in the field of Design and Engineering, Product Design and
          Development, Drafting, Computer aided design, FEA, CFD, Manufacturing Engineering
          . We are a focused team of engineers who have a passion for
          engineering which empowers us to provide our global customers with support throughout
          the entire product design and development process, from concept design to prototype
          testing and validation</p>
        <p>We strive to exceed our client’s expectations and to provide a unique design
          experience. We provide design and detail engineering services along with fabrication
          drawings and structural 3D models and 2D drawings to various industrial sectors like,
          Manufacturing Industry, Turbo- machinery, Aerospace, Automotive, Industrial Products
          and Machines, Oil and Gas.</p>
      </div>
    </div>
  )
}

export default AboutSegmentTwo