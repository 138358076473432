import React, { useRef, useState } from 'react'
import './Nav.css'
import MainLogo from '../../ASSETS/MainLogo/final-01.png'

import {FaFacebook, FaTwitter,  } from 'react-icons/fa';
import {FiInstagram, FiDribbble, FiLinkedin} from 'react-icons/fi';
import {AiOutlineMenu} from 'react-icons/ai';


import {NavLink} from 'react-router-dom'

function Nav() {

    const dropelem = useRef(null)


    const [open, setopen] = useState(false)

    const handlemenubutton = () => {
        setopen(!open);
        // console.log(dropelem);
        if(open){
            dropelem.current.style.display = "none"

        }
        else{
        dropelem.current.style.display = "initial"

        }
        console.log(open)
    }

    const [pages, setPages] = useState([
        {page: "Home", link : '/'},
        {page: "About", link: '/AboutUs'},
        {page: "Services", link: "/Services"},
        {page: "Contact Us", link: "/ContactUs"}
    ])

  return (
    <div className='nav'>
        <div className="nav_logo">
            <img src={MainLogo} alt="" />
            {/* <h3>Draft-It</h3> */}
        </div>
        <div className="nav_mobile">
           
            <button onClick={handlemenubutton}> <AiOutlineMenu/></button>

        </div>

        <div ref={dropelem} className="allLinks_mobile">
                <div id="menubtn"><button onClick={handlemenubutton}><AiOutlineMenu/></button></div>
                <div className="nav_logo">
            <img src={MainLogo} alt="" />
            {/* <h3>Draft-IT</h3> */}
        </div>
                    {/* <img style={{"height": "2vmax"}} src={MainLogo} alt="" /> */}
                    <div id="allLinks_mob">

                        {pages.map((p, i) => <div ref={dropelem} key={i} className='links_mob'  >
                            <div className="dot"></div>
                            <NavLink onClick={handlemenubutton} style={({ isActive }) => isActive ? { color: 'rgb(121, 239, 255)', } : {}} to={p.link} className="link_mob" end={p.page === "Home" && "true"}>{p.page}</NavLink>

                            <div className="line"></div>
                        </div>
                        )}

                    </div>
                </div>

        <div className="nav_desktop">
        <div className="nav_pages">
            {pages.map((page, i) => {
                return <NavLink style={({ isActive }) => isActive ? { color: 'red' } : {}} end={page.page === "Home" && "true"} to={page.link}>{page.page}</NavLink>
            })}
        </div>
        <div className="nav_icons">
           <a href="http://Twitter.com/DraftIT_design"><FaTwitter/></a> 
            <a href="https://www.linkedin.com/company/draft-it-design-solutions/"><FiLinkedin/></a>
            <a
        href="https://wa.me/+917979779691"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
        </div>

        
        </div>
    </div>
  )
}

export default Nav